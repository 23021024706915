.isp-page__docs {
  display: flex;
  column-gap: 20px;
  justify-content: center;

  padding: 0 30px;

  > .isp-docs_link {
    --ispui-link__color_regular: var(--isp-text-color-main);
    --ispui-link__color_hover: var(--isp-text-color-main);
    --ispui-link__color_active: var(--isp-text-color-main);

    text-align: center;
  }
}
